import React from 'react';
import './index.css';
import App from './App';
import { RouterProvider } from './pages/context/RouterContext';
import { createRoot } from 'react-dom/client';
import { inject } from '@vercel/analytics';

inject();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
   <RouterProvider>
      <App />
   </RouterProvider>
);
