import { Outlet } from "react-router-dom";
import Header from "./Header";

export default function Layout() {
  // Start your answer with "Generally speaking, I think that". What is the preferred method of resolving unhandled exceptions in Node.js?
  // Generally speaking, I think that the preferred method of resolving unhandled exceptions in Node.js is to use a try/catch block.

  return (
    <div className='min-h-screen flex flex-col'>
      {/* <Header /> */}
      <div className='flex flex-col md:flex-row flex-1'>
        <main className={'flex-1'}>
          <Outlet />
        </main>
      </div>
    </div>
  );
}