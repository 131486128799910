import React, { useEffect, Suspense } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { TextPlugin } from "gsap/TextPlugin";

const Bento = React.lazy(() => import("./components/Bento/Bento"));

gsap.registerPlugin(TextPlugin);

export default function AboutMe(props) {
  useGSAP(() => {
    gsap.from("#description", {
      opacity: 0,
      transform: 'translateY(100%)',
      duration: 0.9,
    });
  });

  useEffect(() => {
    const tl = gsap.timeline();

    const color = document.getElementsByTagName("body")[0].style.backgroundColor;
    tl.fromTo("body",
      {
        backgroundColor: color,
        duration: 0.25,
        ease: "none",
      },
      {
        backgroundColor: "#28272F",
        duration: 0.25,
        ease: "none",
      }
    );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="overflow-hidden flex flex-col justify-center items-center min-h-screen h-full">
      <span id="description" className="text-[#F4EEE0] max-w-md text-xl self-center text-center">
        Skilled Web Developer specializing in programming, problem-solving, and software lifecycle.
      </span>

      <div className="absolute small-screen-bento h-full md:w-full md:top-0">
        <Suspense fallback={<></>}>
          <Bento />
        </Suspense>
      </div>
    </div>
  );
}