import { useState, useEffect, useMemo, useContext } from "react";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { useNavigate, useLocation } from "react-router-dom";
import { RouterContext } from "../pages/context/RouterContext";

export default function ScrollBar(props) {
  const location = useLocation();
  const { routes } = useContext(RouterContext);
  const navigate = useNavigate();
  const totalScrolls = routes?.length - 1;
  const [currentScrollSnap, setCurrentScrollSnap] = useState(0);
  let startY = 0;

  window.addEventListener('touchstart', (e) => {
    startY = e.touches[0].clientY;
  });

  const scrollSnapper = (e) => {
    let intervalId = null;

    if (e.wheelDeltaY > 0) {
      setCurrentScrollSnap((prev) => {
        if (prev <= 0) {
          return prev;
        } else {
          let target = prev - 1;
          intervalId = setInterval(() => {
            if (prev > target) {
              prev--;
              setCurrentScrollSnap(prev);
            } else {
              clearInterval(intervalId);
            }
          }, 100);
          return prev;
        }
      });
    } else {
      setCurrentScrollSnap((prev) => {
        if (prev >= totalScrolls) {
          return prev;
        } else {
          let target = prev + 1;
          intervalId = setInterval(() => {
            if (prev < target) {
              prev++;
              setCurrentScrollSnap(prev);
            } else {
              clearInterval(intervalId);
            }
          }, 100);
          return prev;
        }
      });
    }
  };

  const touchSnapper = (e) => {
    // e.preventDefault();
    let intervalId = null;
    let direction = startY - e.touches[0].clientY;

    if (direction < 0) {
      setCurrentScrollSnap((prev) => {
        if (prev <= 0) {
          return prev;
        } else {
          let target = prev - 1;
          intervalId = setInterval(() => {
            if (prev > target) {
              prev--;
              setCurrentScrollSnap(prev);
            } else {
              clearInterval(intervalId);
            }
          }, 100);
          return prev;
        }
      });
    } else {
      setCurrentScrollSnap((prev) => {
        if (prev >= totalScrolls) {
          return prev;
        } else {
          let target = prev + 1;
          intervalId = setInterval(() => {
            if (prev < target) {
              prev++;
              setCurrentScrollSnap(prev);
            } else {
              clearInterval(intervalId);
            }
          }, 100);
          return prev;
        }
      });
    }
  };

  window.addEventListener("mousewheel", (e) => {
    scrollSnapper(e);
  });

  window.addEventListener('touchmove', (e) => {
    touchSnapper(e);
  });

  const scrollBarHeight = useMemo(() => {
    const height = 100 / totalScrolls;

    return currentScrollSnap * height;

  }, [totalScrolls, currentScrollSnap]);

  useEffect(() => {
    const scrollSnap = routes[currentScrollSnap];

    const lastRouteIndex = routes.indexOf(location.pathname);
    const currentRouteIndex = routes.indexOf(scrollSnap);

    if (lastRouteIndex === currentRouteIndex) {
      return;
    }

    const tl = gsap.timeline();

    if (lastRouteIndex > currentRouteIndex) {
      tl.fromTo("#App",
        {
          transform: 'translateY(0%)',
          opacity: 1,
        },
        {
          transform: 'translateY(100%)',
          opacity: 0,
        }
      );
    }

    if (lastRouteIndex < currentRouteIndex) {
      tl.fromTo("#App",
        {
          transform: 'translateY(0%)',
          opacity: 1,
        },
        {
          transform: 'translateY(-100%)',
          opacity: 0,
        }
      );
    }

    setTimeout(() => {
      navigate(scrollSnap);
    }, 200);

  }, [currentScrollSnap, location.pathname, navigate, routes]);

  useEffect(() => {
    return () => {
      window.removeEventListener("touchstart", () => { });
      window.removeEventListener("mousewheel", () => { });
      window.removeEventListener("touchmove", () => { });
    };
  }, []);

  useGSAP(() => {
    gsap.to("#topBar", {
      height: `${scrollBarHeight === 100 ? 96 : 4 + scrollBarHeight}px`,
      duration: 0.2,

    });
    gsap.to("#bottomBar", {
      height: `${scrollBarHeight === 100 ? 4 : 96 - scrollBarHeight}px`,
      duration: 0.2,
    });

    if (scrollBarHeight > 0) {
      gsap.to("#scrollText", {
        opacity: 0,
        duration: 0.2,
      });
    } else {
      gsap.to("#scrollText", {
        opacity: 1,
        duration: 0.2,
      });
    }

  }, [scrollBarHeight])

  return (
    <>
      <div className="z-10 flex w-full h-full">
        <div className="absolute flex items-center m-3 bottom-0 right-0">
          <div className="pr-2 z-10">
            <div
              id="topBar"
              className="w-1 mb-1 mt-1 bg-[#474453] rounded-full"
              style={{
                height: 4,
              }}
            />
            <div
              id="staticBar"
              className="w-1 h-3 mb-1 mt-1 bg-[#474453] rounded-full"
            />
            <div
              id="bottomBar"
              className="w-1 bg-[#474453] rounded-full"
              style={{
                height: 96,
              }}
            />
          </div>
        </div>
        <div id="scrollText" className="absolute z-10 flex items-center bottom-[6%] right-[-35px] rotate-90 w-[90px]">
          <span className="text-[#474453]">Scroll Down</span>
        </div>
      </div>
    </>
  );
}