import { useEffect, useRef, useState, useContext, useMemo } from 'react';
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import { gsap } from 'gsap';
import { TransitionGroup } from 'react-transition-group';
import LandingPage from "./pages/LandingPage";
import Layout from "./components/Layout";
import ScrollBar from "./components/ScrollBar";
import AboutMe from './pages/AboutMe';
import Contact from './pages/Contact';
import { RouterContext } from './pages/context/RouterContext';
import { Toaster } from "@/components/ui/sonner"

function AnimatedRoutes() {
  const location = useLocation();
  const appRef = useRef();
  const [lastRoute, setLastRoute] = useState('/');
  const { routes } = useContext(RouterContext);


  useEffect(() => {
    const tl = gsap.timeline();

    const lastRouteIndex = routes.indexOf(lastRoute);
    const currentRouteIndex = routes.indexOf(location.pathname);

    if (lastRouteIndex < currentRouteIndex) {
      tl.fromTo(appRef.current,
        {
          transform: 'translateY(100%)',
          opacity: 0,
        },
        {
          transform: 'translateY(0%)',
          opacity: 1,
        }
      );
    }

    if (lastRouteIndex > currentRouteIndex) {
      tl.fromTo(appRef.current,
        {
          transform: 'translateY(-100%)',
          opacity: 0,
        },
        {
          transform: 'translateY(0%)',
          opacity: 1,
        }
      );
    }

    setLastRoute(location.pathname);
  }, [lastRoute, location, location.key, routes]);

  return (
    <div id="App" className="App" ref={appRef}>
      <TransitionGroup>
        <section className="route-section">
          <Routes location={location}>
            <Route element={<Layout />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="/about" element={<AboutMe />} />
              <Route path="/contact" element={<Contact />} />
            </Route>
          </Routes>
        </section>
      </TransitionGroup>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <ScrollBar />
      <AnimatedRoutes />
      <Toaster />
    </BrowserRouter>
  );
}

export default App;