import { useEffect, useContext } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { TextPlugin } from "gsap/TextPlugin";
import { RouterContext } from "./context/RouterContext";

gsap.registerPlugin(TextPlugin);

function LandingPage() {
  const { routesBg } = useContext(RouterContext);

  useGSAP(() => {
    gsap.from("#textH1", {
      opacity: 0,
      scale: 10,
      duration: 0.5,
      ease: "none",
    });

    gsap.from("#nameH1", {
      opacity: 0,
      scale: 10,
      delay: 0.5,
      ease: "none",
    });

    gsap.from("#textSpan", { x: 0 });

    gsap.to(
      "#textSpan",
      {
        opacity: "100%",
        text: "Full time full stack developer",
        duration: 1,
        delay: 1,
        ease: "none"
      },
    );

    gsap.from("#textSpan2", { x: 0 });

    gsap.to(
      "#textSpan2",
      {
        opacity: "100%",
        text: "based in Santa Catarina, Brazil",
        duration: 1.5,
        delay: 2,
        ease: "none"
      },
    );
  });

  useEffect(() => {
    const tl = gsap.timeline();

    const color = document.getElementsByTagName("body")[0].style.backgroundColor || routesBg['/'];
    tl.fromTo("body",
      {
        backgroundColor: color,
        duration: 0.25,
        ease: "none",
      },
      {
        backgroundColor: "#F4EEE0",
        duration: 0.25,
        ease: "none",
      }
    );
  }, []);

  return (
    <>
      <div className="overflow-hidden flex flex-col justify-center items-center min-h-screen md:min-h-fit md:h-full">
        <div className="absolute w-full h-full overflow-hidden">
          <div id="parallax" className="absolute overflow-hidden" />
          <div id="parallax2" className="overflow-hidden hidden md:inline" />
        </div>

        <div className="flex flex-col p-5 justify-center text-7xl w-full h-full md:text-8xl md:w-2/4">
          <h1 id="textH1" className="text-[#474453] uppercase">Hi there, i`m</h1>
          <h1 id="nameH1" className="text-[#474453] uppercase text-right">Daniel.</h1>
          <span id="textSpan" className="text-[#474453] opacity-0 text-xl self-center">hidden</span>
          <span id="textSpan2" className="text-[#474453] opacity-0 text-xl self-center">hidden</span>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
