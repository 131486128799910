import { useEffect } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { TextPlugin } from "gsap/TextPlugin";
import { Button } from "components/ui/button";
import ContactForm from "./components/ContactForm";

gsap.registerPlugin(TextPlugin);

export default function Contact(props) {
  useGSAP(() => {
    gsap.to('#content', {
      y: 1000,
    });

    gsap.from('#contact-text', {
      y: '50%',
      opacity: 0,
      duration: 0.5,
      ease: "power1.inOut",
    });

    gsap.to('#center-2, #left-2', {
      opacity: 0,
      duration: 0.5,
      delay: 1,
    });

    gsap.to('#right-1', {
      opacity: 0,
      duration: 0.5,
      delay: 1.25,
    });

    gsap.to('#left-1, #right-2', {
      opacity: 0,
      duration: 0.5,
      delay: 1.5,
    });

    gsap.to('#right-3, #left-3, #center-3', {
      opacity: 0,
      duration: 0.5,
      delay: 1.75,
    });

    gsap.to('#content', {
      y: 0,
      duration: 0.5,
      delay: 1.8,
    });
  });

  useEffect(() => {
    const tl = gsap.timeline();

    const color = document.getElementsByTagName("body")[0].style.backgroundColor;
    tl.fromTo("body",
      {
        backgroundColor: color,
        duration: 0.25,
        ease: "none",
      },
      {
        backgroundColor: "#F4EEE0",
        duration: 0.25,
        ease: "none",
      }
    );
  }, []);

  return (
    <>
      <div id="contact-text" className="absolute flex justify-center w-full h-full">
        <div id="left-texts" className="flex flex-col justify-evenly items-center h-full text-7xl left-[-23vw] md:text-9xl">
          <h1 id="left-1" className="text-[#474453] uppercase">CONTACT</h1>
          <h1 id="left-2" className="text-[#474453] uppercase">CONTACT</h1>
          <h1 id="left-3" className="text-[#474453] uppercase">CONTACT</h1>
        </div>
        <div id="center-texts" className="flex flex-col justify-evenly items-center h-full text-7xl md:text-9xl">
          <h1 id="center-1" className="text-[#474453] uppercase">CONTACT</h1>
          <h1 id="center-3" className="text-[#474453] uppercase">CONTACT</h1>
          <h1 id="center-2" className="text-[#474453] uppercase">CONTACT</h1>
        </div>
        <div id="right-texts" className="flex flex-col justify-evenly items-center h-full text-7xl top-0 right-[-23vw] md:text-9xl">
          <h1 id="right-1" className="text-[#474453] uppercase">CONTACT</h1>
          <h1 id="right-2" className="text-[#474453] uppercase">CONTACT</h1>
          <h1 id="right-3" className="text-[#474453] uppercase">CONTACT</h1>
        </div>
      </div>
      <div id="content" className="absolute flex justify-center w-full h-full">
        <div className="mt-80 m-11 p-5 w-full border rounded border-[#474453] bg-[#00000005]">
          <ContactForm />
        </div>
      </div>
    </>
  );
}