import { createContext } from 'react';

export const RouterContext = createContext();

export const RouterProvider = ({ children }) => {
  const routes = ['/', '/about', '/contact'];
  const routesBg = {
    '/': '#F4EEE0',
    '/about': '#28272F',
    '/contact': '#F4EEE0',
  };

  return (
    <RouterContext.Provider value={{ routes, routesBg }}>
      {children}
    </RouterContext.Provider>
  );
};